.root {
    display: inline-block;
    padding: 0;
}

.heading {
    display: inline-flex;
    align-items: center;
    padding: 0 var(--spacing-2) 0 0;
    color: var(--interactive-color);
    font-weight: var(--font-weight-bold);
    outline: 2px solid transparent;
    transition:
        background-color var(--animation-speed-fast) var(--animation-curve),
        outline var(--animation-speed-fast) var(--animation-curve),
        border-radius var(--animation-speed-fast) var(--animation-curve);

    svg {
        margin-right: var(--spacing-1);
        transition: transform var(--animation-speed-fast) var(--animation-curve);
    }

    &[aria-expanded="true"] svg {
        transform: rotate(180deg);
    }

    &:hover {
        cursor: pointer;
        background-color: var(--bg-interactive-color);
        outline: 2px solid var(--bg-interactive-color);
        color: var(--interactive-hover-color);
        border-radius: var(--border-pill-radius);
    }

    &:focus {
        outline: 2px solid var(--border-interactive-color);
        border-radius: var(--border-pill-radius);
    }
}

.body {
    /*  Line up the body content with the chevron icon, plus the spacing between */

    /*  the chevron and the heading. */
    margin-left: calc(var(--spacing-6) + var(--spacing-1));
    margin-bottom: var(--spacing-3);
}

:global(.mcds-redesign-molecules) {

    .root {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--space-row-gap-x-small);
        margin-bottom: var(--space-component-gap-medium);
    }

    button.heading {
        display: flex;
        min-width: var(--space-x-large) !important;
        gap: var(--space-component-gap-small);
        font-weight: var(--font-weight-component);
        font-family: var(--font-family-component);
        font-style: normal;
        line-height: var(--line-height-component);
        background: var(--color-action-passive-subtle);
        color: var(--color-link-text);
        padding-right: var(--space-component-inline-padding-xx-small) !important;

        svg {
            color: var(--color-action-standard);
            margin: 0;
        }
        &:focus,
        &:hover {
            background-color: var(--color-action-passive-subtle-hover);
            outline: 2px solid var(--color-action-passive-subtle-hover);
            color: var(--color-link-text-hover);
            border-radius: var(--radius-action);
            svg {
                color: var(--color-action-standard-hover);
            }
        }

        &:active {
            cursor: pointer;
            background-color: var(--color-action-passive-subtle-active);
            outline: 2px solid var(--color-action-passive-subtle-active);
            color: var(--color-link-text-active);
            border-radius: var(--radius-action);

            svg {
                color: var(--color-action-standard-active);
            }
        }

        &:focus-visible {
            background-color: var(--color-action-passive-subtle-focus);
            outline: 2px solid var(--color-ui-primary);
            color: var(--color-link-text-focus);
            border-radius: var(--radius-action);
            outline-offset: 1px;

            svg {
                color: var(--color-action-standard-focus);
            }
        }

        &[aria-expanded="false"] {

            &:focus,
            &:hover {
                svg {
                    color: var(--color-action-standard-hover);
                }
            }

            &:focus-visible {
                background-color: var(--color-action-passive-subtle-active);
                color: var(--color-action-standard-focus);
            }
        }
    }

    .body {
        display: flex;
        padding: 0 var(--space-component-inline-padding-xxx-large);
        align-items: center;
        gap: var(--space-x-small);
        align-self: stretch;

        color: var(--color-text-primary);
        font-family: var(--font-family-body);
        font-size: var(--font-size-body-3);
        font-style: normal;
        font-weight: var(--font-weight-body);
        line-height: var(--line-height-body);
        letter-spacing: var(--letter-spacing-body);
        max-width: var(--readable-max-width);

        margin: 0;

    }

    .medium {
        height: calc(var(--base-unit) * 5);
        font-size: var(--font-size-component-small);
    }

    .large {
        height: calc(var(--base-unit) * 6);
        font-size: var(--font-size-component-medium);
    }
}
